<script lang="ts" setup>
import { ref, computed } from 'vue';
import { ScuDropdown, ScuSelectOption, ScuToggleButton, ScuToggleButtonItem } from '@scu/vue';
import { SettingsService,UiLayouts } from '@services/utility/SettingsService';
import { LocalLanguages, Locale } from '@customTypes/LocalLanguages';
import { useMsgFormatter } from '@i18n';
import { useStore } from '@store';
import Modal from './Modal.vue';

const { t } = useMsgFormatter();
const store = useStore();

const props = defineProps<{
  onAccept: () => void;
  onCancel: () => void;
}>();

const currentLocalLanguage = ref<Locale>(store.settings.localLanguage);
const currentUILayout = ref<number>(store.settings.layout);

const settingsNotChanged = computed(() => {
 return   (store.settings.layout === currentUILayout.value) && (store.settings.localLanguage === currentLocalLanguage.value);
});

const options = [
  { text: 'Core UI', value: UiLayouts.core_ui.toString() },
  { text: 'Onyx', value: UiLayouts.onyx_ui.toString() },
];

const onChangeLanguage = (event: CustomEvent) => {
  currentLocalLanguage.value = event.detail;
};
const onChangeLayout = (event: CustomEvent) => {
  currentUILayout.value = Number(event.detail);

};
const onAcceptClicked = () => {
  props.onAccept();
  SettingsService.setLocalLanguage(currentLocalLanguage.value);
  SettingsService.setUiLayout(currentUILayout.value);
};
</script>
<template>
  <Modal
    :isVisible="true"
    :headline="'topBar.settings'"
    :onAccept="onAcceptClicked"
    :onCancel="onCancel"
    :disabledAcceptButton="settingsNotChanged"
  >
    <template #content>
      <ScuToggleButton label="UI Layout" @scuvaluechange="onChangeLayout">
        <ScuToggleButtonItem
          v-for="option of options"
          v-bind="option"
          :key="option.value"
          :checked="option.value === String(currentUILayout)"
        />
      </ScuToggleButton>
      <ScuDropdown
        fieldId="language-settings-dropdown"
        :label="t('settings.language')"
        class="dropdown-settings"
        :value="currentLocalLanguage"
        @scuchange="onChangeLanguage"
      >
        <ScuSelectOption :value="LocalLanguages.German">Deutsch</ScuSelectOption>
        <ScuSelectOption :value="LocalLanguages.EnglishUS">English US</ScuSelectOption>
        <ScuSelectOption :value="LocalLanguages.EnglishGB">English GB</ScuSelectOption>
      </ScuDropdown>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.dropdown-settings {
  height: 200px;
  width: 250px;
}
</style>
