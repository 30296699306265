import { reactive } from 'vue';
import type { SiamUser } from '@customTypes/User';
import type { ProductionLocation } from '@services/external/fds/SupplierService';
import { Settings, UiLayouts } from '@services/utility/SettingsService';
import { LocalLanguages } from '@customTypes/LocalLanguages';
import { APIError } from '@api/APIError';
import { EA, SK } from '@services/external/avf/ItemService';
import { TransportRoute } from '@services/external/mds/TransportRouteService';
import {
  CalculationCondition,
  CalculationConditionCategory,
  Country,
  Currency,
  MDSRegionalDistributionCenter,
  PalletModel,
  PriceType,
  Unit,
} from '@customTypes/MDS';

type State = {
  user?: SiamUser;
  // TODO (KKF-725): Support multiple errors (consider error store approach)
  apiError?: APIError;
  decimalError?: Error;
  settings: Settings;
  countries?: Map<string, Country>;
  units?: Map<string, Unit>;
  currencies?: Map<string, Currency>;
  priceTypes?: Map<string, PriceType>;
  transportRoutes?: Map<string, TransportRoute>;
  productionLocations?: Map<string, ProductionLocation>;
  palletModels?: Map<string, PalletModel>;
  regionalDistributionCenters?: Map<string, MDSRegionalDistributionCenter>;
  calculationConditions?: Map<string, CalculationCondition>;
  calculationConditionCategories?: Map<string, CalculationConditionCategory>;
  sks: Map<string, SK>;
  eas: Map<string, EA>;
  linksBySK: Map<string, Map<string, string>>; // Map<SK, Map<EA, quantity>>
};

const initialState: State = {
  user: undefined,
  apiError: undefined,
  settings: {
    localLanguage: LocalLanguages.German,
    layout: UiLayouts.onyx_ui,
  },
  countries: undefined,
  units: undefined,
  currencies: undefined,
  priceTypes: undefined,
  transportRoutes: undefined,
  productionLocations: undefined,
  palletModels: undefined,
  regionalDistributionCenters: undefined,
  calculationConditions: undefined,
  sks: new Map<string, SK>(),
  eas: new Map<string, EA>(),
  linksBySK: new Map<string, Map<string, string>>(),
};

// this Store should only be used by services to update it
export const Store = reactive(initialState);

// this useStore is intended to be used by UI components
export const useStore = (): Readonly<State> => Store;
