<script lang="ts" setup>
import { Store } from '@store';
import { useMsgFormatter } from '@i18n';
import { ScuToast } from '@scu/vue';
import { ErrorService } from '@services/utility/ErrorService';

const { t } = useMsgFormatter();
</script>
<template>
  <div class="toast-wrapper" :hidden="!Store.apiError">
    <ScuToast status="error" closable @scuclose="ErrorService.dismissError">
      <div slot="message">{{ t('error.general') }}</div>
      <div slot="description">{{ Store.apiError?.message }} <br />Request ID: {{ Store.apiError?.requestId }}</div>
    </ScuToast>
  </div>
</template>
