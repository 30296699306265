<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import { ScuBottomBar, ScuBottomBarAction, ScuHeadline, ScuModalDialogue, ScuIcon } from '@scu/vue';
import { useMsgFormatter } from '@i18n';
import type { MessageOrId } from '@i18n/types';

const { t } = useMsgFormatter();

const props = defineProps<{
  headline: MessageOrId;
  onAccept: () => void;
  acceptButtonLabel?: MessageOrId;
  disabledAcceptButton?: boolean;
  loadingAcceptButton?: boolean;
  onCancel: () => void;
  cancelButtonLabel?: MessageOrId;
  hideCancelButton?: boolean;
  hideAcceptButton?: boolean;
  icon?: string;
}>();
</script>
<template>
  <ScuModalDialogue class="modal" :isVisible="true" isModal>
    <div slot="header" :class="{ 'with-icon': props.icon }">
      <ScuIcon size="XL" :src="icon" color="var(--orange-5)" v-if="props.icon" />
      <ScuHeadline element="h2">{{ t(props.headline) }}</ScuHeadline>
    </div>
    <div slot="body">
      <slot name="content"></slot>
    </div>
    <ScuBottomBar slot="footer" position="component">
      <ScuBottomBarAction
        v-if="!props.hideCancelButton"
        slot="secondary-actions"
        :text="t(props.cancelButtonLabel ?? 'general.abort')"
        @scuclick="props.onCancel"
      />
      <ScuBottomBarAction
        v-if="!props.hideAcceptButton"
        slot="primary-action"
        :text="t(props.acceptButtonLabel ?? 'general.apply')"
        @scuclick="props.onAccept"
        :disabled="props.disabledAcceptButton ?? false"
        :loading="props.loadingAcceptButton ?? false"
      />
    </ScuBottomBar>
  </ScuModalDialogue>
</template>
