import { APIError } from '@api/APIError';
import { Store } from '@store';

// TODO (KKF-726): Discuss support for diferent error handling (toast, dialog, screen)
// TODO (KKF-725): Support multiple errors (consider error store approach)

const catchAPIError = (err: APIError): undefined => {
  Store.apiError = err;
  console.error(err.message);
};

const catchDecimalError = (err: Error): undefined => {
  Store.decimalError = err;
  console.error(err.message);
};


const dismissError = () => (Store.apiError = undefined);

export const ErrorService = {
  catchAPIError,
  dismissError,
  catchDecimalError,
};
