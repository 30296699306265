import { Locale, LocalLanguages } from '@customTypes/LocalLanguages';
import { changeLocale } from '@i18n';
import { Store } from '@store';

export type Settings = {
  localLanguage: Locale;
  layout:UiLayouts;
};
export enum UiLayouts {
  core_ui,
  onyx_ui,
}

const setLocalLanguage = (language: Locale) => {
  Store.settings.localLanguage = language;
  switch (language) {
    case LocalLanguages.EnglishGB:
      changeLocale('en');
      break;
    case LocalLanguages.EnglishUS:
      changeLocale('en');
      break;
    default:
      changeLocale('de');
  }
};

const setUiLayout = (layout: number) => {
 
  switch (layout) {
    case UiLayouts.core_ui:
      Store.settings.layout = UiLayouts.core_ui;
      break;
      case UiLayouts.onyx_ui:
        Store.settings.layout = UiLayouts.onyx_ui;
        break;
    default:
      Store.settings.layout = UiLayouts.core_ui;
  }
};

export const SettingsService = { setLocalLanguage, setUiLayout };
