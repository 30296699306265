// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const env = (window as any)['env'];

type Environment = {
  AUTH_IDP: string;
  AUTH_CLIENT_ID: string;
  API_HOST: string;
  KKF_GATEWAY_URL: string;
  BFF_GATEWAY_URL: string;
  AVF_GATEWAY_URL: string;
};

export const environment: Environment = import.meta.env.PROD
  ? {
      AUTH_IDP: env['auth_idp'],
      AUTH_CLIENT_ID: env['auth_client_id'],
      API_HOST: env['kkf_host'],
      KKF_GATEWAY_URL: env['kkf_link'],
      BFF_GATEWAY_URL: env['bff_gateway_link'],
      AVF_GATEWAY_URL: env['avf_link'],
    }
  : {
      AUTH_IDP: 'https://psftp-keycloak.dev.sit.svc.odj.cloud/auth/realms/PSF',
      AUTH_CLIENT_ID: 'psf-web-ui-test',
      API_HOST: 'http://localhost:3000',
      KKF_GATEWAY_URL: 'https://kontraktkonditionsverwaltung-food.api.dev.sit.sys.odj.cloud',
      BFF_GATEWAY_URL: 'https://ava-api-rest-gateway.api.dev.sit.sys.odj.cloud',
      AVF_GATEWAY_URL: 'https://artikelverwaltung-food.api.dev.sit.sys.odj.cloud',
    };
