import { NavigationGuard } from 'vue-router';
import { useStore } from '@store';

export type TokenObj = {
  aud: string[];
  domain: string;
};

// Factory function for the authorization guard
export const createAuthorizationGuard = () => {
  const guard: NavigationGuard = async (to) => {
    const { requiresAuth, requiredRoles, requiredAuds } = to.meta;
    const user = useStore().user;

    // If the user is not logged in and the route is protected stop navigation immediately
    if (requiresAuth && !user) {
      return { path: '/error', query: { requestedPath: to.path } };
    }

    // Let the user pass if auth is not required or no authorization roles are set
    if (!requiresAuth || !requiredRoles) {
      return true;
    }

    const userRoles = user?.profile.roles ?? [];
    const userHasRequiredRoles = (requiredRoles as string[]).every((requiredRole) => userRoles.includes(requiredRole));

    // audience validation
    const part = user?.access_token?.split('.')[1] ?? '';
    let decoded;
    let token: TokenObj;

    try {
      decoded = window.atob(part);
      token = JSON.parse(decoded);
    } catch (e) {
      // TODO (KKF-1958): Show global error toast
      return { path: '/error', query: { requestedPath: to.path } };
    }
    const userHasRequiredAud = (requiredAuds as string[]).every((requiredAud) => token.aud.includes(requiredAud));

    if (userHasRequiredRoles && userHasRequiredAud) {
      return true;
    }

    return { path: '/error', query: { requestedPath: to.path } };
  };

  return guard;
};
