<script setup lang="ts">
import { ref } from 'vue';
import TopBar from '@ui/shared/TopBar.vue';
import SettingsModal from '@ui/shared/SettingsModal.vue';
import ErrorToast from '@ui/shared/ErrorToast.vue';

const isSettingsModalVisible = ref(false);
const openSettingsModal = () => {
  isSettingsModalVisible.value = true;
};
const closeSettingsModal = () => {
  isSettingsModalVisible.value = false;
};
</script>

<template>
  <div scu-theme="lidl" class="container">
    <div>
      <TopBar :onSettingsClick="openSettingsModal" />
    </div>
    <div class="body">
      <router-view />
      <SettingsModal v-if="isSettingsModalVisible" :onAccept="closeSettingsModal" :onCancel="closeSettingsModal" />
      <ErrorToast />
    </div>
  </div>
</template>
<style scoped>
.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.body {
  overflow: hidden;
  flex: 1;
  display: flex;
}

</style>
